<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <variety-statistics />
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import VarietyStatistics from '../../components/dashboard/VarietyStatistics.vue'

export default {
  name: 'ManagerGraphFungicides',
  middleware: ['auth', 'manager'],
  components: {
    VarietyStatistics
  },
  metaInfo () {
    return {
      title: 'Crop Varieties Graph'
    }
  },

  mounted () {
    core.index()
  }
}
</script>
